$gray: #999;
$light-gray: #eee;

/* Front page news. */
ul.news {
  .date {
    color: $gray;
    font-weight: bold;
  }

  > li {
    margin-top: 1em;
  }
}

@media (min-width: 576px) {
  .project-people {
    column-count: 2;
  }
  .card-columns {
    column-count: 2;
  }
}

@media (min-width: 768px) {
  .role.grad {
    column-count: 2;
  }
  .project-people {
    column-count: 3;
  }
  .project-people-factor {
    column-count: 2;
  }
  .card-columns {
    column-count: 3;
  }
}

.title img {
  max-width: 50%;
}

.profile {
  object-fit: cover; // makes sure it's cropped
}

.noprofile {
  circle {
    fill: $light-gray;
  }
  text {
    font-weight: 700;
    fill: white;
  }
}

.card {
  .profile {
    height: 40px;
    width: 40px;
    margin: 3px;
  }
}

.person {
  margin-bottom: 10px;

  .profile {
    height: 70px;
    width: 70px;
  }

  .bio {
    color: $gray;
  }

  .person-name {
    margin: 0;
  }
}

.person-without-image {
  margin: 0;

  .bio {
    margin-left: 1em;
  }
}

.logo-image {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  overflow: hidden;
  margin-top: -6px;
  float: left;
}
